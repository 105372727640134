.action-button-list {
  max-width: 200px;
  position: relative;
  button {
    margin: 0;
    width: 100%;
  }
  &-items {
    width: 100%;
    box-shadow: 0 0 5px #00000026;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    border-radius: 5px;
    display: none;
    &.open {
      display: block;
    }
    button {
      text-align: left;
      background: none;
      border: none;
      display: block;
      padding: 10px 20px;
      font-size: 14px;
      color: #707070;
      font-weight: 500;
      i {
        margin-right: 20px;
      }
    }
  }
}
