@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,600;0,700;0,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #707070;
}

*,
::after,
::before {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}
