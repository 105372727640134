.update {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 20px #00000016;
  padding: 20px;
  margin-bottom: 20px;
  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .icon {
      background-color: #fff4e5;
      display: inline-block;
      padding: 10px;
      border-radius: 50%;
      margin-right: 15px;
      span {
        background-color: #ffca83;
        color: #ffffff;
        font-size: 12px;
        padding: 5px;
        width: 28px;
        text-align: center;
        border-radius: 50%;
        display: block;
      }
    }
  }
  &-list {
    &-item {
      box-shadow: 0 0 23px #00000010;
      padding: 15px;
      color: #9f9f9f;
      font-size: 12px;
      margin-bottom: 20px;
      border-radius: 5px;
      &-title {
        color: #707070;
        padding-left: 25px;
        position: relative;
        font-size: 14px;
        &::after {
          content: "";
          width: 12px;
          height: 12px;
          background-color: #ffa035;
          display: block;
          position: absolute;
          top: 6px;
          border-radius: 50%;
          left: 0;
        }
      }
    }
  }
}
